import getClientApp from "@/firebase/client";
import { getAuth, signInAnonymously, signOut, browserSessionPersistence, browserLocalPersistence,signInWithCustomToken } from "firebase/auth";
import APIService from "./api-service";
import SessionStorageService from "./session-storage-service";
/*
*   Wrappers for Firebase authentication functions
*/

export default abstract class AuthenticationService {

    /*
    *   Sign in with custom token created in api/auth
    */
    public static async signInWithCustomToken( token: string    ) {
        await getAuth( getClientApp() ).setPersistence(browserSessionPersistence);
        await signInWithCustomToken( getAuth( getClientApp() ), token );
    }

    /*
    *   Anonymous sign in for testamenttikysely
    */
    public static async signInAnonymously() {
        await getAuth( getClientApp() ).setPersistence(browserLocalPersistence); 
        await signInAnonymously( getAuth( getClientApp() ));
        await APIService.postWithUidToken( '/api/user/create', {} );  
    }

    /*
    *   Sign out from Firebase
    */
    public static async signOut() {
        SessionStorageService.removeWill('WILL'); 
        SessionStorageService.removeWill('WILL_REGISTRATION'); 
        signOut( getAuth( getClientApp() ) );
        
    }


    /*
    *   Get ID token used in request to securely get current user on the backend
    */
    public static async getAuthUserIdToken() {
        return await getAuth( getClientApp() ).currentUser?.getIdToken(true);
    }

    
}