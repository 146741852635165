import type { Will, ProductName, WillTypes } from "@/types";

export default abstract class SessionStorageService {
    

    public static setWill( will:Will, type: WillTypes ) {
        if ( will.fields ) {
            sessionStorage.setItem( type, JSON.stringify( will ) );
            return true;
        }
        return false;
    }

    public static getWill( type: WillTypes ): Will | null {
        const will = sessionStorage.getItem( type );
        if ( will ) {
            try {
                return JSON.parse( will );
            }
            catch(e) {
                return null;
            }
        }
        return null;
    }

    public static removeWill( type: WillTypes) {
        sessionStorage.removeItem( type ); 
    }

    public static setOrderID( product: ProductName, orderId: string ) {
        sessionStorage.setItem(`order[${product}]`, orderId);
    }

    public static getOrderID( product: ProductName ): string | null {
        return sessionStorage.getItem( `order[${product}]` );
    }

    public static removeOrderID( product: ProductName ) {
        sessionStorage.removeItem( `order[${product}]` ); 
    }

}