import { useState, useEffect } from 'react';
import ContentProvider, { PageContentField } from '@/providers/content-provider';
import STKButton from '@/components/common/button';
import STKLoader from '@/components/common/loader';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import STKIcon from '@/components/common/icon';
import HTMLHead from '@/components/template-parts/html-head';
import Footer from '@/components/template-parts/footer';
import MobileNavigation, { Logo } from '@/components/common/navigation-logo';
import css from '@/styles/pages/frontpage.module.scss';
import useUser from '@/lib/useUser';
import STKSignOut from '@/components/common/sign-out';
import ROUTES from '@/routes';
import { PageProps } from '@/types';
import DBService from '@/util/db-service';
import Script from 'next/script';
import useNotification from "@/lib/useNotification";
import { useSearchParams } from 'next/navigation'
import AuthenticationService from "@/util/authentication-service";

const Section = ( props: any ) => {

  return (
    <div id={props.id} className={css.section}>
      <div className={css.section__columns}>
        <div className={css.section__column + ' ' + ( ! props.inverted ? '' : css['section__column--grey'] )}>
          <div>
            {props.children}
          </div>
        </div>
        <div className={css.section__column + ' ' + css['section__column--right'] + ' ' + ( props.inverted ? '' : css['section__column--grey'] )}>
          <div>
            {props.list}
            <p className='mt-4'><b>Hinta {props.price} €</b></p>
            <div className={css.section__button}>
              <STKButton href={props.button?.href}>{props.button?.text}</STKButton> 
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}


const WillSection = () => {
  return(
    <Section 
      id="hautajaistestamentti"
      price="25" 
      button={{text: 'Tee Hautajaistestamentti', href: ROUTES.WILL.BASE}} 
      list={
        <>
          <div className={`${css.list} mb-6`}>
            <PageContentField section='will-description' index={1} />
          </div>
          <div className={`${css.list}`}>
            <PageContentField section='will-description' index={2} />
          </div>
        </>
      }
    >
        <STKIcon icon="will"/>
        <PageContentField section='will-description' index={0} />
    </Section>
  );
}

const WillRegistrationSection = () => {
  return(
    <Section 
      id="testamenttirekisteri"
      price="25" 
      button={{text: 'Rekisteröi', href: ROUTES.WILL_REGISTRATION.BASE}} 
      list={
        <div className={`${css.list}`}>
          <PageContentField section='registration-description' index={1} />
        </div>
      }
    >
        <STKIcon icon="will_registration"/>
        <PageContentField section='registration-description' index={0} />
    </Section>
  );
}

const QuerySection = () => {
  return(
    <Section 
      id="testamenttikysely"
      inverted
      price="50" 
      button={{text: 'Tee Testamenttikysely', href: ROUTES.QUERY.BASE}}
      list={
        <div className={`${css.list}`}>
          <PageContentField section='query-description' index={1} />
        </div>
      }
    >
        <STKIcon icon="query"/>
        <PageContentField section='query-description' index={0} />
    </Section>
  );
}




export const getStaticProps = async () => {
  return { props: { content: await DBService.getPage(ROUTES.FRONT_PAGE) } as PageProps  };
}
const HomePage = ( props: PageProps) => {

  const { authUser, isLoading } = useUser();

  const [ readMoreWill, setReadMoreWill ] = useState(false);
  const [ readMoreRegistration, setReadMoreRegistration ] = useState(false);
  const [ readMoreQuery, setReadMoreQuery ] = useState(false);

  const { addNotification, Notification } = useNotification();

  const searchParams = useSearchParams();

  const scrollTo = ( id:string ) => {
    const scrollTopOffset = document.getElementById(id)?.offsetTop;
    if ( scrollTopOffset ) {
      window.scrollTo({top: scrollTopOffset - 100, behavior: 'smooth'});
    }
  }

  // Scroll to section when view more (mobile view) is activated
  useEffect( () => { if ( readMoreWill ) scrollTo('hautajaistestamentti') }, [readMoreWill] );
  useEffect( () => { if ( readMoreQuery ) scrollTo('testamenttikysely') }, [readMoreQuery] );

  //Sign out
  useEffect( () => {
    if ( searchParams.has('signout') ) {
      AuthenticationService.signOut()
        .then( () => {
        })
        .catch( () => {
            addNotification('ERROR');
        })
    }
  }, [searchParams]);

 

  return (
    <ContentProvider content={props.content}>
      <Script src="https://kit.fontawesome.com/6b12185db4.js" crossOrigin="anonymous" />
      <HTMLHead title='' description='Tee ja hae hautajaistestamentti netissä.' />
      <STKSignOut authUser={authUser} /> 
      <div className={css.mainContainer} style={{'--background-image': `url(/body-bg.webp)`, '--background-image-fallback': `url(/body-bg.png)`} as React.CSSProperties }>
        <MobileNavigation authUser={authUser} />
        <main className={css.main}> 
          <Notification />
          <div className={css.header}>
            <div className={css.header__logo}>
              <Logo />
            </div>
           { <PageContentField section='header' index={0} />}
            <div className={css.header__columns}>

              {/* Hautajaistestamentti */}
              <div className={css.header__column}>
                <div className={css.header__columnInner}>
                  <div>
                    <h5><STKIcon icon="will" size="small" />Hautajais&shy;testamentti</h5>
                    <PageContentField section='header' index={1} />
                  </div>
                  <div>
                    <div className={css.header__desktopButton}>
                      <STKButton simple onClick={() => { scrollTo('hautajaistestamentti') }}>Lue lisää</STKButton>
                    </div>
                    <div className={css.header__mobileButton}>
                      <STKButton simple onClick={() => { setReadMoreWill(true) }}>Lue lisää</STKButton>
                    </div>
                  </div> 
                </div>
                <div className={css.mobileContent}>
                  {readMoreWill && <WillSection /> }
                </div>
              </div>

              {/* Testamenttirekisteri */}
              <div className={css.header__column}>
                <div className={css.header__columnInner}>
                  <div>
                    <h5><STKIcon icon="will_registration" size="small"/>Testamentti&shy;rekisteri</h5>
                    <PageContentField section='header' index={2} />
                  </div>  
                  <div>
                    <div className={css.header__desktopButton}>
                      <STKButton simple onClick={() => { scrollTo('testamenttirekisteri') }}>Lue lisää</STKButton>
                    </div>
                    <div className={css.header__mobileButton}>
                      <STKButton simple onClick={() => { setReadMoreRegistration(true) }}>Lue lisää</STKButton>
                    </div>
                  </div>
                </div>
                <div className={css.mobileContent}>
                  {readMoreRegistration && <WillRegistrationSection /> }
                </div>
              </div>

              {/* Testamenttikysely */}
              <div className={css.header__column + ' ' + css['header__column--kysely']}>
                <div className={css.header__columnInner + ' ' + css['header__columnInner--kysely']}>
                  <div>
                    <h5><STKIcon icon="query_white" size="small"/>Testamenttikysely</h5>
                    <PageContentField section='header' index={3} />
                  </div>  
                  <div>
                    <div className={css.header__desktopButton}>
                      <STKButton white onClick={() => { scrollTo('testamenttikysely') }}>Lue lisää</STKButton>
                    </div>
                    <div className={css.header__mobileButton}>
                      <STKButton white onClick={() => { setReadMoreQuery(true) }}>Lue lisää</STKButton>
                    </div>
                  </div>
                </div>
                <div className={css.mobileContent}>
                  {readMoreQuery && <QuerySection /> }
                </div>
              </div>


            </div> 
          </div>
          <div className={css.desktopContent}>
            <WillSection />
            <WillRegistrationSection />
            <QuerySection /> 
          </div>
          
        </main>
      </div>
      <Footer />
    </ContentProvider>
  )
}

export default HomePage;
