
import { useState } from "react";
import STKNotification from "@/components/common/notification";

type NotificationTypes = 'ERROR' | 'VALIDATION_ERROR' | 'TERMS_VALIDATION_ERROR' | 'TWILIO_VALIDATION_ERROR';

const notifications: { [key in NotificationTypes]: string } = {
    ERROR: 'Jotain meni vikaan. Yritä myöhemmin uudelleen.',
    VALIDATION_ERROR: 'Virhe kentässä.',
    TERMS_VALIDATION_ERROR: 'Hyväksy ehdot',
    TWILIO_VALIDATION_ERROR: 'Puhelinnumero ei ole käytössä' 
}

const useNotification = () => {

    const [ notification, setNotification ] = useState('');

    const addNotification = ( message:NotificationTypes ) => {
        window.scrollTo({top: 0, behavior: 'smooth'});
        if ( ! notification ) {
            setNotification( notifications[message] );
            setTimeout( () => {
                setNotification('');
            }, 5000);
        }
    }

    const Notification = () => <STKNotification type="error">{notification}</STKNotification>

    return { addNotification, Notification }
}

export default useNotification;