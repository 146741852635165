import AuthenticationService from "./authentication-service";
/*
*   Wrappers for API request
*/


export default abstract class APIService {

    public static async postWithUidToken( endpoint: string, body:any ) {

        const uidToken = await AuthenticationService.getAuthUserIdToken();

        const res = await fetch( endpoint, {
            method: 'POST',
            body: JSON.stringify({
                data: body
            }),
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
                'Authorization': `Bearer ${uidToken}` 
            }
        });

        return await res.json();
        
    }

    public static async post( endpoint: string, body:any ) {
        const res = await fetch( endpoint, {
            method: 'POST',
            body: JSON.stringify({
                data: body
            }),
            headers: {
                'Content-type': 'application/json; charset=UTF-8'
            }
        });

        return await res.json();
    }

}