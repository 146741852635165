import { useState, useEffect } from "react";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import SessionStorageService from "@/util/session-storage-service";
import getClientApp from "@/firebase/client";

export type User = {
    uid: string
}

/*
*   Hook for keeping track of Firebase auth status
*/
const useUser = ( anonymous:boolean = false ) => {

    const firebase = getClientApp();

    const [ authUser, setAuthUser ] = useState<User|null>(null);
    const [ isLoading, setIsLoading] = useState<boolean>(true); 

    useEffect( () => {
        
        const unsubscribe = onAuthStateChanged(getAuth(firebase), async ( user ) => {
         
            if ( ! user ) {

                SessionStorageService.removeWill('WILL');
                SessionStorageService.removeWill('WILL_REGISTRATION');
                SessionStorageService.removeOrderID('WILL');
                SessionStorageService.removeOrderID('QUERY');

                setAuthUser(null)
                setIsLoading(false);
                return;
            }

            if ( anonymous !== user.isAnonymous ) {
                setAuthUser(null)
                setIsLoading(false);
                return;
            } 

            setIsLoading(true);
            setAuthUser({
                uid: user.uid
            } as User ); 
            setIsLoading(false);

        });
        return () => unsubscribe();
    }, []);
    

    return { authUser, isLoading };
}

export default useUser; 