import { motion } from 'framer-motion';

const STKAnimated = ( props: any ) => {

    const animationTypes:any = {
        'down-to-up': {
            initial: { y: "50px", opacity: 0 },
            animate: { y: 0, opacity: 1 }
        },
        'up-to-down': {
            initial: { y: "-50px", opacity: 0 },
            animate: { y: 0, opacity: 1 }
        }
    }

    return(
        <motion.div {...props} initial={animationTypes[props.type]?.initial} animate={animationTypes[props.type]?.animate}>
            {props.children}
        </motion.div>
    );
}

export default STKAnimated;