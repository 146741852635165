import css from "./styles.module.scss";
import { AnimatePresence } from "framer-motion"
import STKAnimated from "../animated";
type Props = {
    children: JSX.Element | string,
    type: 'error'
}

const STKNotification = ( props:Props ) => {


    return(
        <AnimatePresence>
            { props.children &&
                <STKAnimated exit={{ y:"-20px", opacity: 0 }} type="down-to-up">
                    <div className={`${css.notification} ${css['notification--'+props.type]}` }>  
                        <div>
                            {props.children}
                        </div>
                    </div>
                </STKAnimated>
            }
        </AnimatePresence>
    );
}

export default STKNotification;